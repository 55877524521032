import { init, captureException } from "./utils/bugTracker";

export function isBugTrackerDisabled(): boolean {
  let localStorageFlag = false;
  let urlParamFlag = false;

  try {
    if (window.localStorage) {
      localStorageFlag =
        window.localStorage.getItem("__sc_is_bug_tracker_disabled") === "true";
    }
  } catch (err) {
    console.error("Unable to access localStorage:", err.message || err.stack);
  }

  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.get("disable_bug_tracker") !== null) {
    urlParamFlag = true;
  }

  return localStorageFlag || urlParamFlag;
}

export function isBugTrackerForceEnabled(): boolean {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("enable_bug_tracker") !== null;
}

export function onError(event: ErrorEvent | PromiseRejectionEvent): boolean {
  // todo: should we attempt clearing redux persist storage when recovering? that's defo not allowed when offline
  //  but may help clean up corrupted persisted data issues when online

  const error: Error =
    "reason" in event
      ? (event as PromiseRejectionEvent).reason
      : (event as ErrorEvent).error;

  if (error) {
    console.error(error.message);
    captureException(error);
  }

  return true;
}

export class ErrorRecoveryHandler {
  private isEventAdded = false;
  public register = (): void => {
    if (!this.isEventAdded) {
      this.isEventAdded = true;
      window.addEventListener("error", onError);
      window.addEventListener("unhandledrejection", onError);
    }
  };

  public unregister = (): void => {
    window.removeEventListener("error", onError);
    window.removeEventListener("unhandledrejection", onError);
  };
}

export function errorHandlerInitial(): void {
  if (
    process.env.REACT_APP_SENTRY_DSN &&
    (isBugTrackerForceEnabled() ||
      (!isBugTrackerDisabled() &&
        process.env.REACT_APP_SC_ENV &&
        process.env.REACT_APP_SC_ENV !== "development"))
  ) {
    // IMPORTANT: sentry is initialised globally. It's important for error handler script to be loaded before main script
    //  because main script is going to be using the same global sentry object, initialised here.
    init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_SC_ENV || "development",
      // todo: replace hash with semver string
      release: process.env.REACT_APP_GITHASH || "dev_version",
    });
  }

  const recoveryHandler = new ErrorRecoveryHandler();
  recoveryHandler.register();
}

errorHandlerInitial();
