import * as Sentry from "@sentry/browser";
import { CaptureContext, Severity } from "@sentry/types";

export interface BugTrackerInitPayload {
  dsn: string;
  environment: string;
  release: string;
}

export interface LogContext {
  level: LogLevel;
  extra: {
    [key: string]: any;
  };
  contexts: {
    [key: string]: any;
  };
  tags: {
    [key: string]: string;
  };
  fingerprint: string[];
}

export type LogLevel =
  | "fatal"
  | "error"
  | "warning"
  | "log"
  | "info"
  | "debug"
  | "critical";

const ignoreMessages: string[] = [
  /**
   *  https://github.com/screencloud/studio-player/issues/501
   *  Basically a warning that is thrown as an error. Complaints easy to find on Stackoverflow.
   */
  "ResizeObserver loop limit exceeded",
];

export function init(payload: BugTrackerInitPayload): void {
  Sentry.init({
    ...payload,
    beforeSend: (event) => {
      if (
        ignoreMessages.findIndex((message) => {
          return (
            (event.message && new RegExp(message, "i").test(event.message)) ||
            (event.exception?.values &&
              event.exception?.values.findIndex(
                (error) =>
                  error.value && new RegExp(message, "i").test(error.value)
              ) > -1)
          );
        }) > -1
      ) {
        return null;
      }

      return event;
    },
  });
}

export function captureMessage(
  message: string,
  context?: Partial<LogContext> | LogLevel
): void {
  let sentryContext: CaptureContext | Severity;

  if (typeof context === "string") {
    sentryContext = context as Severity;
  } else {
    sentryContext = context as CaptureContext;
  }
  Sentry.captureMessage(message, sentryContext);
}

export function captureException(
  error: Error,
  context?: Partial<LogContext>
): void {
  Sentry.captureException(error, context as CaptureContext);
}

export function setTag(key: string, value: string): void {
  Sentry.configureScope((scope) => {
    scope.setTag(key, value);
  });
}
